import { objToQuery } from '@minutemailer/utils';
import RequestError from '@lib/RequestError';
import getCookie from '@lib/getCookie';

export default function request(
    endpoint,
    data = null,
    method = 'GET',
    base = '/api/',
) {
    let url = `${base}${endpoint}`;

    if (method === 'GET' && data && typeof data === 'object') {
        url += `?${objToQuery(data)}`;
    }

    const headers = {
        Accept: 'application/json',
        'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
    };

    if (!(data instanceof FormData)) {
        headers['Content-Type'] = 'application/json';
    }

    const body = data && method !== 'GET' ? JSON.stringify(data) : null;

    return fetch(url, {
        method,
        credentials: 'same-origin',
        body: data instanceof FormData ? data : body,
        headers,
    })
        .then((r) =>
            r.json().then((json) => {
                if (r.status > 299) {
                    throw new RequestError(r.statusText, json, r.status);
                }

                return json;
            }),
        )
        .catch((ex) => {
            if (!(ex instanceof RequestError)) {
                throw new RequestError(
                    ex.message,
                    { message: 'Unknown error' },
                    501,
                );
            }

            throw ex;
        });
}
